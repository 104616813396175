<div class="modal-header util-uppercase" id="modal-title">
	{{ 'VideoSettings' | translate }}
</div>
<div class="modal-body modal-body-message">
	<span>
		{{ 'Media_BulkEdit_OperationSubmitted_Msg1' | translate }}
	</span>

	<div class="padding-top-10" [hidden]="!legalHoldVideosCount">
		<span [hidden]="!isEditing">{{ 'Media_BulkEdit_OperationSubmitted_Msg2' | translate : { '0': legalHoldVideosCount } }}</span>
		<span [hidden]="!isDeleting">{{ 'Media_BulkEdit_DeleteOperationSubmitted_Msg2' | translate : { '0': legalHoldVideosCount } }}</span>
	</div>

	<div class="padding-top-10" [hidden]="!(recordingVideosCount > 0 && isDeleting)">
		<span>{{ 'Media_BulkEdit_DeleteOperationSubmitted_Msg3' | translate : { '0': recordingVideosCount } }}</span>
	</div>

	<div class="padding-top-10">
		{{ 'Media_BulkEdit_OperationSubmitted_Msg3' | translate }}
	</div>
</div>
<div class="modal-footer">
	<button type="submit" (click)="modalInstance.hide()" autofocus vbBtnPrimary>
		{{ 'Ok' | translate }}
	</button>
</div>
