<div dropdown vbDropdown #notificationsDropdown="bs-dropdown" class="dropdown" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']" [ngClass]="dropdownCssClass">
	<button dropdownToggle aria-controls="notification-dropdown" [attr.aria-label]="'Notifications' | translate" class="notifications dropdown-toggle main-nav-btn line-height-1" id="notifications-button" type="button" vbBtnPrimary [ignoreVbBtnPrimary]="!isBaseHeaderBgColor" [ngClass]="[
			notificationsDropdown.isOpen ? 'open' : '',
			isHigh ? 'high': '',
			!isBaseHeaderBgColor ? 'btn': '',
			unreadCount > 99 ? unreadCount > 999 ? 'large-count-bubble' : 'medium-count-bubble' : ''
		]">
		<div class="flex-center-align">
			<span [ngClass]="[
					!notificationsDropdown.isOpen || !showRemove ? 'vb-icon vb-icon-notification' : '',
					notificationsDropdown.isOpen && showRemove ? 'glyphicons remove_2 rotate-360-once': ''
				]">
			</span>
			<span class="btn-notify notifications-count" *ngIf="unreadCount">
				{{ notificationCount }}
			</span>
		</div>
	</button>
	<div *dropdownMenu id="notification-dropdown" class="notification-menu dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="notifications-button">
		<notifications-menu-content [ngClass]="[isBaseHeaderBgColor ? 'theme-header' : 'theme-accent']" (close)="notificationsDropdown.isOpen = false">
		</notifications-menu-content>
	</div>
</div>

