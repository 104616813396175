import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, switchMap, map, BehaviorSubject, tap } from 'rxjs';

import { isEmbed } from 'rev-shared/util/AppUtil.Service';
import { CacheFactory } from 'rev-shared/util/CacheFactory';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { DefaultEmojis } from 'rev-shared/htmlEditor/EmojiData';
import { IComment } from 'rev-shared/webcast/webcastView/chat/Contract';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { SharedDeviceService } from 'rev-shared/device/SharedDevice.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { promiseFromTimeout } from 'rev-shared/util/PromiseUtil';

import { updateWaitingOrStartingStatus } from './WebcastStatusUpdateHelper';
import { IWebcastReactionsSettings, WebcastModel } from './model/WebcastModel';
import { PollsModelService } from '../polls/PollsModel.Service';
import { WebcastPresentationService } from '../presentations/WebcastPresentation.Service';

@Injectable({
	providedIn: 'root'
})
export class WebcastService {
	private webcastCache = new CacheFactory<WebcastModel>(3);

	private blockedChatUsersPromise: Promise<void>;
	private chatBlockedUsersSubject$ = new BehaviorSubject<{ chatAuthorBlocked: boolean, [key: string]: boolean }>({ chatAuthorBlocked: false });
	public chatBlockedUsers$ = this.chatBlockedUsersSubject$.asObservable();


	constructor(
		private DateParsers: DateParsersService,
		private ListenerConnection: WebRtcListenerConnectionService,
		private PollsModelSvc: PollsModelService,
		private PushBus: PushBus,
		private PushService: PushService,
		private SecurityContext: SecurityContextService,
		private SharedDevice: SharedDeviceService,
		private UserContext: UserContextService,
		private UserLocalIPService: UserLocalIPService,
		private WebcastPresentationSvc: WebcastPresentationService,
		private http: HttpClient,
		private zone: NgZone,
		private MediaFeaturesService: MediaFeaturesService
	) {}

	public getWebcast(webcastId: string, refetch?: boolean, noCache?: boolean): Promise<WebcastModel> {
		const cachedWebcast = !refetch ? this.webcastCache.get(webcastId) : null;

		if (cachedWebcast){
			return Promise.resolve(cachedWebcast);
		}

		return Promise.resolve(this.SecurityContext.$promise)
			.then(() => lastValueFrom(this.http.get<any>(`/scheduled-events/${webcastId}`)))
			.then( eventResult => {
				const scheduledEvent = eventResult.scheduledEvent;
				Object.assign(scheduledEvent, {
					id: webcastId,
					accountId: this.UserContext.getAccount().id,
					attendees: scheduledEvent.attendees || [],
					backgroundImages: eventResult.backgroundImages,
					endDate: this.DateParsers.parseUTCDate(scheduledEvent.endDate),
					moderatorIds: scheduledEvent.moderatorIds || [],
					externalPresenters: scheduledEvent.externalPresenters || [],
					startDate: this.DateParsers.parseUTCDate(scheduledEvent.startDate),
					thumbnailUri: eventResult.thumbnailUri,
					previewThumbnailUri: eventResult.previewThumbnailUri,
					mediaCachingEnabled: eventResult.mediaCachingEnabled,
					videoHeartbeatInterval: eventResult.videoHeartbeatInterval,
					environmentId: eventResult.environmentId,
					recordingVideoStatus: eventResult.recordingVideoStatus,
					customFields: eventResult.customFields,
					eventHostName: eventResult.eventHostName,
					liveSubtitles: {
						isLiveSubtitlesEnabled: !!scheduledEvent.liveSubtitles,
						...scheduledEvent.liveSubtitles
					},
					webcastBrandingSettings: {
						logoUri: eventResult.logoUri,
						...scheduledEvent.webcastBrandingSettings
					},
					vodInfo: scheduledEvent.vodInfo && {
						...scheduledEvent.vodInfo,
						id: scheduledEvent.vodId,
						durationMs: this.DateParsers.parseTimespan(scheduledEvent.vodInfo.duration),
					},
					vcDtmfControlsEnabled: scheduledEvent.dtmfControlsEnabled,
					producerBgImages: scheduledEvent.producerBgImages,
					customConsentDetails: scheduledEvent.customConsentDetails,
					reactionsSettings: getReactionSettings(scheduledEvent.reactionsSettings)
				});

				if(scheduledEvent.presentationFile){
					scheduledEvent.presentationFile.lastUpdated = this.DateParsers.parseUTCDate(scheduledEvent.presentationFile.lastUpdated);
				}

				if(scheduledEvent.preProduction){
					scheduledEvent.preProduction.durationMs =
						this.DateParsers.parseTimespan(scheduledEvent.preProduction.duration);
				}

				const canEditEvents = this.SecurityContext.checkAuthorization('events.edit');

				const isAccountAdmin = this.SecurityContext.checkAuthorization('admin.accounts.edit')
					// No parent/ancestor account admin
					&& this.UserContext.getAccount().id == scheduledEvent.accountId;

				const webcast = new WebcastModel(scheduledEvent, {
					canEditEvents,
					canJoinPreProduction: eventResult.canJoinPreProduction,
					canJoinMainEvent: eventResult.canJoinMainEvent,
					canViewReports: eventResult.canViewReports,
					isAccountAdmin,
				}, {
					DateParsers: this.DateParsers,
					ListenerConnection: this.ListenerConnection,
					PollsModelService: this.PollsModelSvc,
					PushBus: this.PushBus,
					UserContext: this.UserContext,
					UserLocalIPService: this.UserLocalIPService,
					WebcastPresentationService: this.WebcastPresentationSvc,
					WebcastService: this,
					zone: this.zone
				});

				updateWaitingOrStartingStatus(webcast);

				if (!noCache) {
					this.webcastCache.put(webcastId, webcast);
				}

				return webcast;
			});
	}

	public getWebcastEmbeddedContent(webcastId: string, isPreviewCache?: boolean): Promise<any> {
		return this.MediaFeaturesService.getFeatures().then((features: IMediaFeatures) => {
			const { isAvailable, isEnabled } = features.webcastEmbedContentSettings;
			const embeddedContentEnabled = isAvailable && isEnabled;

			return embeddedContentEnabled ? lastValueFrom(this.http.get<any>(
				isPreviewCache ?
					`/scheduled-events/${webcastId}/embedded-content/preview` :
					`/scheduled-events/${webcastId}/embedded-content`
			)) : Promise.resolve();
		});
	}

	public getWebcastInfo(webcastId: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/${webcastId}/access-control`))
			.then(webcast => {
				webcast.registrationFields?.forEach(f => f.value = '');
				webcast.startDate = this.DateParsers.parseUTCDate(webcast.startDate);
				webcast.endDate = this.DateParsers.parseUTCDate(webcast.endDate);
				return webcast;
			});
	}

	public registerGuestUser(webcastId: string, userInfo: any): Promise<any> {
		const query = isEmbed() ? '?tk' : '';
		return lastValueFrom(this.http.post(`/auth/scheduled-events/${webcastId}/guests${query}`, {
			name: userInfo.name,
			email: userInfo.email,
			password: userInfo.password,
			registrationFieldsAnswers: userInfo.registrationFieldsAnswers,
			skipPreRegistration: userInfo.skipPreRegistration
		}));
	}

	public registerAnonymousGuestUser(webcastId: string, userInfo: any): Promise<any> {
		const query = isEmbed() ? '?tk' : '';
		return lastValueFrom(this.http.post(`/auth/scheduled-events/${webcastId}/anonymous-guests${query}`, {
			password: userInfo.password
		}));
	}

	public cancelScheduledAutomatedWebcast(webcastId: string, runNumber: number): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:CancelScheduledAutomatedWebcast', { webcastId, runNumber });
	}

	public endWebcast(webcastId: string, runNumber: number, discardRecording: boolean): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:EndWebcast', { webcastId, runNumber, discardRecording });
	}

	public startBroadcasting(webcast: WebcastModel ): Promise<void> {
		return Promise.resolve(webcast.isWebrtcSinglePresenter && !webcast.currentUser.isSinglePresenter &&
			this.PushService.dispatchCommand('scheduledEvents:StartBroadcastCountdown', { webcastId: webcast.id })
				.then(() => promiseFromTimeout(5000))
		).then(() =>
			this.PushService.dispatchCommand('scheduledEvents:StartBroadcasting', {
				webcastId: webcast.id,
				autoRecording: webcast.recording.initiateAutoRecording
			}));
	}

	public restartWebcastVideoSource(webcastId: string, runNumber: number, message: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:RestartWebcast', { webcastId, runNumber, message });
	}

	public stopBroadcasting(webcast: WebcastModel): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:StopBroadcasting', {
			webcastId: webcast.id
		});
	}

	public startRecording(webcast: WebcastModel): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:StartRecording', {
			webcastId: webcast.id
		});
	}

	public getRecordingDeviceDetails(deviceId: string): Promise<any> {
		return this.SharedDevice.getDmeDevice(deviceId);
	}

	public stopRecording(webcast: WebcastModel): Promise<any> {
		const isAlreadyProcessing = webcast.recording.isProcessingRequest;

		webcast.recording.isProcessingRequest = true;

		return new Promise((resolve, reject) => {
			const unsubscribe = this.PushBus.subscribe(webcast.id, {
				StopRecordingFailed: msgData => {
					unsubscribe();
					webcast.recording.isProcessingRequest = false;
					reject(msgData);
				},

				RecordingStopped: msgData => {
					unsubscribe();
					webcast.recording.isProcessingRequest = false;
					resolve(msgData);
				}
			});

			if (!isAlreadyProcessing) {
				this.PushService.dispatchCommand('scheduledEvents:StopRecording', {
					webcastId: webcast.id
				})
					.catch(err => {
						webcast.recording.isProcessingRequest = false;
						unsubscribe();

						return reject(err);
					});
			}
		});
	}

	public addWebcastComment(webcast: WebcastModel, comment: any): Promise<string> {
		return this.PushService.dispatchCommand('scheduledEvents:AddWebcastComment', {
			webcastId: webcast.id,
			runNumber: webcast.currentRun.runNumber,
			isEventAdminPM: comment.isEventAdminPM,
			comment: comment.comment,
			IsAdminOrModerator: comment.isAdminOrModerator
		}, 'CommandFinished') //Force signalr impl
			.$commandId;
	}

	public getWebcastComments(webcast: WebcastModel): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/${webcast.id}/${webcast.currentRun.runNumber}/comments`))
			.then(result =>
				(result.comments || []).map(comment => {
					comment.date = this.DateParsers.parseUTCDate(comment.date);
					return comment;
				}));
	}

	public addUserReaction(webcastId: string, emoji: string): Promise<any> {
		return lastValueFrom(this.http.post<any>(`/scheduled-events/${webcastId}/reactions`, { emoji }))
			.then(result => {
				return result;
			});
	}

	public getUserReactions(webcastId: string): Observable<{ char: string, count: number }> {
		return this.PushBus.getRxJsObservable(webcastId, 'UserReactions', ['UserReactionsPublished'])
			.pipe(
				switchMap(e => from((e.data.reactions as any[]).map(r => ({
					char: r.emoji,
					count: r.count
				}))))
			);
	}

	public leave(webcast: WebcastModel, discardRecording: boolean = false): Promise<void> {
		if (webcast.currentUser.isEventAdmin && !webcast.isAutomated){
			return this.endWebcast(webcast.id, webcast.currentRun.runNumber, discardRecording);
		}
		return Promise.resolve();
	}

	public fetchPreRegisteredGuestInfo(webcastId: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/${webcastId}/prereg-guest`));
	}

	public sendVcCallControl(webcastId: string, dtmf: string): Promise<any> {
		return this.PushService.dispatchCommand('scheduledEvents:SendVcCallControl', {
			webcastId,
			dtmf
		});
	}

	public updateCommentStatus(webcast: WebcastModel, comment: IComment): Promise<any> {
		return this.PushService.dispatchCommand('scheduledEvents:UpdateWebcastCommentStatus', {
			webcastId: webcast.id,
			runNumber: webcast.currentRun.runNumber,
			commentId: comment.id,
			userId: comment.userId,
			hide: !comment.hidden
		});
	}

	public disableAttendeeToComment(webcast: WebcastModel, userId: string, disable: boolean): Promise<any> {
		const command = disable ? 'scheduledEvents:MuteWebcastAttendee' : 'scheduledEvents:UnmuteWebcastAttendee';

		return this.PushService.dispatchCommand(command, {
			webcastId: webcast.id,
			runNumber: webcast.currentRun.runNumber,
			userId
		});
	}

	public getChatBlockedUsers(webcast: WebcastModel): Observable<any> {
		if (!webcast.isChatEnabled) {
			return;
		}

		return this.http.get<string[]>(`/scheduled-events/${webcast.id}/${webcast.currentRun.runNumber}/users/muted`)
			.pipe(
				map((response: any) => {
					const mutedUsersObject = response.mutedUsers?.reduce((acc, userId) => {
						acc[userId] = true;
						return acc;
					}, {});

					const data = {
						chatAuthorBlocked: response.isUserMuted,
						...mutedUsersObject
					};
					return data;
				}),
				tap(data => this.setChatBlockedUsers(data)),
				switchMap(()=> this.subscribeChatBlockedEvents(webcast))
			);
	}

	public setChatBlockedUsers(newData: { chatAuthorBlocked: boolean, [key: string]: boolean }): void {
		const data = this.chatBlockedUsersSubject$.value;

		const updatedData = {
			...data,
			...newData
		};

		this.chatBlockedUsersSubject$.next(updatedData);
	}

	private subscribeChatBlockedEvents(webcast: WebcastModel): Observable<any> {
		const onAttendeeBlocked = (data: any, isBlocked: boolean) => {
			const blockedUsers = this.chatBlockedUsersSubject$.value;

			if (webcast.id === data.webcastId) {
				const chatAuthorBlocked = data.userId === this.UserContext.getUser().id ? isBlocked : blockedUsers.chatAuthorBlocked;

				const blockUser = { chatAuthorBlocked, [data.userId]: isBlocked };
				this.setChatBlockedUsers(blockUser);
			}
		};

		return this.PushBus.getObservable(webcast.id, '', {
			WebcastAttendeeUnmuted: (data: any) => {
				onAttendeeBlocked(data, false);
			},
			WebcastAttendeeMuted: (data: any) => {
				onAttendeeBlocked(data, true);
			}
		});
	}
}

export function getReactionSettings(data?: any): IWebcastReactionsSettings {
	data = data || { enabled: false, emojis: [] };

	data.emojis = !data.emojis.length
		? DefaultEmojis
		: data.emojis.map(({ name, character }) => ({ name, char: character }));

	return data;
}
