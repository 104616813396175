import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { StateService, TransitionPromise } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { FileUtil } from 'rev-shared/util/FileUtil';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';

import { defaultColors } from 'rev-portal/branding/Defaults';
import { InsightSearchHelper } from 'rev-portal/search/InsightSearchHelper.Service';

import { TeamRoleType, Team, StatusHandler, ITeamMember } from './Team.Contract';
import { TeamService } from './Team.Service';

import './manage-team.less';

@Component({
	selector: 'manage-team',
	templateUrl: './ManageTeam.Component.html'
})
export class ManageTeamComponent implements OnInit {
	@Input() public accountId: string;
	@Input() public previousState: { name: string; params: any };
	@Input() public portalTeamAdminAccess: boolean;
	@Input() public teamId: string;
	@Input() public isAdminTheme: boolean;

	public addingImage: boolean;
	public image: any;
	public imageUrl: string;
	public statusHandler: StatusHandler;
	public team: Team;
	public teamForm: NgForm;
	public teamInsightOptions: any;
	public logoFile: any;

	public isCreateMode: boolean;
	public readonly primaryThemeColor: string = defaultColors.accentColor;
	public imageFileExtensions = FileUtil.imageFileExtensions;
	@ViewChild('teamName') public teamName: NgModel;
	@ViewChild('logoImageFileUpload') public logoImageFileUpload: NgModel;
	@ViewChild(VbConfirmationDialogComponent) public deleteConfirmation: VbConfirmationDialogComponent;

	constructor(
		private $state: StateService,
		private TeamService: TeamService,
		private InsightSearchHelper: InsightSearchHelper,
		public UserContext: UserContextService,
		private TranslateService: TranslateService
	) {
	}

	public ngOnInit() {
		this.team = this.tryGetTeam(this.teamId);
		this.isCreateMode = !this.teamId;
		this.portalTeamAdminAccess = !this.previousState ? true : this.portalTeamAdminAccess;
		this.setInsightOptions();
		this.statusHandler = new StatusHandler();
		this.statusHandler.setActiveStatus();
		this.logoFile = { name: '' };
		this.image = {};
		this.imageUrl = this.team.thumbnailUri;
	}

	public tryGetTeam(teamId: string): Team {
		if (!teamId) {
			return new Team(this.accountId,
				this.TeamService.isSetToDefaultAsTeamAdmin() ? this.UserContext.getUser().id : null);
		}

		return this.TeamService.teams[teamId];
	}

	public get defaultColor(): string {
		return this.primaryThemeColor;
	}

	public createThenReset(): void {
		this.submitTeam().then(() => {
			this.image = {};
			this.imageUrl = null;
			this.statusHandler.setActiveStatus();
			this.team = new Team(this.accountId,
				this.TeamService.isSetToDefaultAsTeamAdmin() ? this.UserContext.getUser().id : null);
			//this.teamForm.name.$setPristine();
		});
	}

	public setLogoImageFile({ file }): Promise<any> {
		this.image = {
			name: file.name,
			progress: 0,
			status: { uploading: true },
			file
		};

		this.logoImageFileUpload.control.setValue(file.name); //force dirty flag to be set.
		this.addingImage = true;

		return file.getImageUrl()
			.then(result => this.imageUrl = result )
			.finally(() => {
				this.addingImage = false;
				this.validateLogo(file);
			});
	}

	public removeLogoImage(): void {
		Object.assign(this.team, {
			thumbnailUri: null,
			imageId: null
		});

		this.image = {};
		this.imageUrl = null;
	}

	public submit(): void {
		this.submitTeam()
			.then(() => this.redirectToPreviousState())
			.catch(err => console.error('Error creating team:', err));
	}

	public cancel(): void {
		this.redirectToPreviousState();
	}

	public submitTeam(): Promise<any> {
		this.statusHandler.setLoadingStatus();

		return this.tryToUploadImage()
			.then(imgId => this.initiateTeamSave(imgId));
	}

	public initiateTeamSave(imgId: string): Promise<any> {
		this.team.imageId = imgId;
		return (this.isCreateMode ?
			this.TeamService.createTeam(this.team) :
			this.TeamService.saveTeam(this.team))
			.catch(err => this.handleError(err));
	}

	public onDeleteTeam(): void {
		this.deleteConfirmation.open().result
			.then(() => {
				this.statusHandler.setLoadingStatus();
				this.TeamService.removeTeam(this.accountId, this.team.id)
					.then(() => {
						if (this.previousState) {
							return this.$state.go('portal.team');
						}
						return this.$state.go('portal.admin.user-access.teams');
					})
					.catch(err => this.statusHandler.setErrorStatus())
					.finally(() => this.statusHandler.setActiveStatus());
			});
	}

	public tryToUploadImage(): Promise<any> {
		if (!this.image?.name) {
			return Promise.resolve(this.team.imageId);
		}
		return this.TeamService.uploadLogo(this.accountId, this.team.id, this.image.file);
	}

	private setInsightOptions(): void {
		this.teamInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			groups: true,
			users: true,
			getEntityIds: () => (this.team.teamMembers || []).map(e => e.id),
			mapQueryPage: page => ({
				items: page.items.map(
					entity => this.assignDefaultRoleType(entity)
				),
				count: page.count
			})
		});
		this.teamInsightOptions.teamRoleTypes = Object.values(TeamRoleType);
	}

	private assignDefaultRoleType(accessEntity: ITeamMember): ITeamMember {
		if (this.team.teamMembers.some(user => user.id === accessEntity.id)) {
			return accessEntity;
		}

		return {
			...accessEntity,
			roleTypes: [TeamRoleType.TeamMember]
		};
	}

	public redirectToPreviousState(): TransitionPromise {
		if (this.previousState?.name) {
			return this.$state.transitionTo(this.previousState.name, this.previousState.params);
		}
		return this.$state.go('portal.admin.user-access.teams', { team: this.teamId });
	}

	public validateLogo(file): void {
		if (!file?.isImageFile) {
			this.logoImageFileUpload.control.setErrors({ fileType: true });
		}
	}

	private handleError(err: any): Promise<never> {
		if(err.hasIssue('TeamNameInUse')) {
			this.statusHandler.setActiveStatus();
			this.teamName.control.setErrors({ teamNameInUse: true });
		}
		return Promise.reject(err);
	}
}
