import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { TemplatesMenuAngularModule } from 'rev-shared/ui/templates/TemplatesMenuAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { VbDropdownMenuModule } from 'rev-shared/ui/dropdown-menu/VbDropdownMenu.Module';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';

import { GoLiveComponent } from './GoLive.Component';
import { GoLiveFormComponent } from './GoLiveForm.Component';

const declarations = [
	GoLiveComponent,
	GoLiveFormComponent
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		BsDropdownModule,
		ButtonsAngularModule,
		CommonModule,
		FormDirectivesAngularModule,
		FormsModule,
		FormGroupAngularModule,
		SecurityAngularModule,
		SelectAngularModule,
		TemplatesMenuAngularModule,
		TextInputAngularModule,
		TranslateModule,
		ValidationAngularModule,
		VbDropdownMenuModule,
	]
})

export class GoLiveModule {}
