import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { VbDropdownMenuModule } from 'rev-shared/ui/dropdown-menu/VbDropdownMenu.Module';

import { NotificationAngularModule } from '../../notifications/NotificationAngular.Module';
import { NotificationsMenuButtonComponent } from './NotificationsMenuButton.Component';
import { NotificationsMenuContentComponent } from './NotificationsMenuContent.Component';

const components = [
	NotificationsMenuButtonComponent,
	NotificationsMenuContentComponent
];

@NgModule({
	declarations: [
		components
	],
	imports: [
		ButtonsAngularModule,
		CommonModule,
		TranslateModule,
		NotificationAngularModule,
		BsDropdownModule,
		UIRouterModule,
		CssRulesAngularModule,
		SecurityAngularModule,
		VbDropdownMenuModule
	],
	exports: [
		components
	]
})
export class NotificationsMenuModule { }
