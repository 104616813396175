import moment from 'moment';

import { IEmbeddedContent } from 'rev-portal/scheduledEvents/common/EmbeddedContentTypes';
import { IBannerDetails } from 'rev-portal/scheduledEvents/common/BannerTypes';

import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { IAccessEntityIdentifier, SearchService } from 'rev-portal/search/Search.Service';
import { ILiveSubtitles } from 'rev-shared/ui/liveSubtitles/ILiveSubtitles';
import { ITemplateInfo } from 'rev-shared/webcast/WebcastTemplates.Contract';
import { IVideoField } from 'rev-shared/metadata/Metadata.Contract';
import { parseTimespan } from 'rev-shared/date/DateParsers.Service';

import { AttendeeJoinMethod, EventAccessControl } from '../EventAccessControl';
import { IRtmpSettings } from './IRtmpSettings';
import { PresentationModel } from '../presentations/PresentationModel';
import { WebcastQuestionOption, WebcastStatus } from '../webcast/WebcastStatus';
import { WebcastVideoSource } from '../webcast/WebcastVideoSource';
import { WebcastType } from '../webcast/WebcastType';
import { IPreProductionCfg, IPoll, IRegistrationFieldSelection } from './EditWebcast.Contract';
import { IWebcastBrandingSettings } from 'rev-portal/scheduledEvents/webcast/WebcastBranding';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { ICustomConsentDetails, IProducerBgImage, IVodInfo, IWebcastReactionsSettings } from '../webcast/model/WebcastModel';
import { IExternalPresenter } from 'rev-shared/webcast/webcastView/producer/Contract';
import { EmojiChar } from 'rev-shared/htmlEditor/EmojiData';

export interface IEditProducerImage extends IProducerBgImage {
	clone?: boolean;
}

export class EditWebcastModel {
	public id: string;
	public cloneWebcastId?: string;
	public title: string;
	public shortcutName: string;
	public description: string;
	public startDate: Date = moment().startOf('h').toDate();
	public endDate: Date = moment(this.startDate).add(1, 'h').toDate();
	public timezoneId: string;
	public lobbyTimeMinutes: number = 15;
	public categoryIds: string[] = [];
	public categories: any[] = [];
	public tags: string[] = [];
	public accessControl: EventAccessControl = EventAccessControl.Private;
	public attendeeJoinMethod: AttendeeJoinMethod;
	public password: string;
	public estimatedAttendees: number;
	public unlisted: boolean = false;
	public videoSource: WebcastVideoSource = WebcastVideoSource.PRESENTATION;
	public presentationProfileId: string;
	public presentationProfileName?: string;
	public vcSipAddress: string;
	public vcSipPin: string;
	public vcDtmf: string;
	public vcDtmfControlsEnabled = false;
	public vcMicrosoftTeamsMeetingUrl: string;
	public webexTeam: any;
	public automatedWebcast: boolean = false;
	public autoplay: boolean = true;
	public closedCaptionsEnabled: boolean = false;
	public customFields: IVideoField[] = [];

	public emailToPreRegistrants: boolean;

	public eventAdminIds: string[];
	public eventAdmins: IAccessEntityIdentifier[];

	public moderatorIds: string[] = [];
	public moderators: IAccessEntityIdentifier[];
	public userIdSlideControl: string;

	public presenterIds: string[] = [];
	public presenters: IAccessEntityIdentifier[];
	public externalPresenters: IEditPresenter[];

	public accessEntities: IAccessEntityIdentifier[];
	public groupIds: string[] = [];
	public userIds: string[] = [];

	public chatEnabled: boolean = true;
	public pollsEnabled: boolean = false;
	public userPollsResponse;
	public questionAndAnswerEnabled: boolean = false;
	public questionOption: WebcastQuestionOption = WebcastQuestionOption.IDENTIFIED;

	public autoAssociateVod: boolean = true;
	public disableAutoRecording: boolean = false;
	public recordingUploaderUsers: IAccessEntityIdentifier[];
	public recordingUploaderUserId: string;
	public redirectVod: boolean = true;

	public backgroundImageFile: FileWrapper;
	public thumbnailUri: string;
	public imageId: string;
	public templateImageId?: string;
	public isBackgroundFill: boolean = false;

	public previewThumbnailFile: FileWrapper;
	public previewThumbnailImageId: string;
	public previewThumbnailUri: string;
	public templatePreviewThumbnailImageId?: string;

	public presentationFile?: FileWrapper | { name: string };
	public originalPresentationFile?: { name: string };
	public presentationFileDownloadAllowed: boolean = false;
	public presentation: PresentationModel = {} as PresentationModel;

	public polls: IPoll[];
	public originalPolls: { readonly [pollId: string]: IPoll };

	public hideShareUrl : boolean = false;

	public preProduction: IPreProductionCfg;
	public status: WebcastStatus;
	public registrationFields: IRegistrationFieldSelection[];
	public liveSubtitles: ILiveSubtitles;
	public enableCustomBranding: boolean = false;
	public webcastBrandingSettings: IWebcastBrandingSettings = {};
	public webcastType: WebcastType;
	public rtmpSettings: IRtmpSettings;
	public zoom: {
		meetingId?: string;
		meetingPassword?: string;
	} = {};
	public presenterId: string;
	public presenter: any;
	public vodId: string;
	public vodInfo?: IVodInfo;
	public estimatedDurationHours: number;
	public viewerIdEnabled?: boolean;
	public viewerIdWatermarkText: string;
	public embeddedContent: IEmbeddedContent = {
		isEnabled: false,
		contentLinks: []
	};
	public bannerDetails: IBannerDetails = {
		isEnabled: true,
		banners: []
	};

	public producerBgImages: IEditProducerImage[];
	public customConsentDetails: ICustomConsentDetails;
	public isCustomConsentEnabled: boolean;
	public consentVerbiage: string;
	public reactionsSettings: IWebcastReactionsSettings;

	constructor(
		data: any,
		private SecurityContext: SecurityContextService,
		private SearchService: SearchService,
		private UserContext: UserContextService
	) {
		const polls = data?.polls || [];
		this.applyWebcastData({
			...data,
			polls,
			originalPolls: polls.reduce((polls, p) => ((polls[p.id] = { ...p }), polls), {}),
			originalPresentationFile: data?.presentationFile,
			externalPresenters: (data.externalPresenters || []).map(readExternalPresenter)
		});
	}

	public get preRegistristrationEnabled(): boolean {
		return this.accessControl === EventAccessControl.Public;
	}

	public get isDtmfSource(): boolean {
		return this.videoSource === WebcastVideoSource.SIP_ADDRESS || this.videoSource === WebcastVideoSource.PEXIP || this.videoSource === WebcastVideoSource.ZOOM;
	}

	public applyTemplate(template: ITemplateInfo, webcast?: EditWebcastModel): Promise<void> {
		const metadata = template.metadata;

		if (
			!this.SecurityContext.checkAuthorization('events.setEventPublic') &&
			(template.metadata.accessControl === EventAccessControl.Public || template.metadata.accessControl === EventAccessControl.TrustedPublic)
		) {
			template.metadata.accessControl = webcast?.accessControl || EventAccessControl.AllUsers;
		}

		if (
			!this.SecurityContext.checkAuthorization('media.useRevIq') && template.metadata.liveSubtitles?.sourceLanguage
		) {
			template.metadata.liveSubtitles = webcast?.liveSubtitles || { isLiveSubtitlesEnabled: false };
		}

		return this.SearchService.userHasEditAccessToVideo(this.UserContext.getAccount().id, metadata.vodId)
			.then(hasEditAccess => {
				if (!hasEditAccess) {
					metadata.vodId = undefined;
					metadata.vodInfo = undefined;
				}
				this.applyWebcastData({
					...metadata,
					userIds: metadata.userIds || [],
					groupIds: metadata.groupIds || [],
					thumbnailUri: template.thumbnailUri,
					previewThumbnailUri: template.previewThumbnailUri,
					templateImageId: template.imageId,
					templatePreviewThumbnailImageId: template.previewThumbnailImageId,
					polls: (metadata.polls || []).map(p => ({
						...p,
						answers: p.answers.map(text => ({ text }))
					})),
					enableCustomBranding: metadata.enableCustomBranding || false,
					webcastBrandingSettings: {
						...metadata.webcastBrandingSettings,
						logoUri: template.logoUri
					},
					customFields: template.customFields,
					webcastType: metadata.webcastType,
					externalPresenters: (metadata.externalPresenters || []).map(p => {
						const presenter = readExternalPresenter(p);
						presenter.isNew = true;
						return presenter;
					}),
					embeddedContent: {
						isEnabled: !!metadata.embeddedContent?.isEnabled,
						contentLinks: (metadata.embeddedContent?.contentLinks || []).map(link => ({ ...link, id: undefined, resourceUri: undefined, isEnabled: false }))
					},
					bannerDetails: {
						isEnabled: !!metadata.bannerDetails?.isEnabled,
						banners: (metadata.bannerDetails?.banners || []).map(link => ({ ...link, id: undefined, isEnabled: false }))
					},
					producerBgImages: template.producerBgImages?.map(img => ({
						...img,
						clone: true
					})),
					customConsentDetails: {
						isCustomConsentEnabled: !!template.customConsentDetails?.isCustomConsentEnabled,
						consentVerbiage: template.customConsentDetails?.consentVerbiage,
						customConsentVerbiageUpdates: webcast?.customConsentDetails?.customConsentVerbiageUpdates
					}
				});
			});
	}

	public setAssignableCategories(assignableCategoryIds: string[]) {
		this.categoryIds = this.categoryIds.filter(x => (assignableCategoryIds || []).includes(x));
		this.categories = this.categories.filter(x => this.categoryIds.includes(x.categoryId));
	}

	private applyWebcastData(data: any) {
		Object.assign(this, data, {
			eventAdmins: mapAccessEntity(AccessEntityType.User, data.eventAdminIds),
			moderators: mapAccessEntity(AccessEntityType.User, data.moderatorIds),
			presenters: mapAccessEntity(AccessEntityType.User, data.presenterIds),
			accessEntities: mapAccessEntity(AccessEntityType.User, data.userIds)
				.concat(mapAccessEntity(AccessEntityType.Group, data.groupIds)),
			recordingUploaderUsers: mapAccessEntity(AccessEntityType.User, [data.recordingUploaderUserId].filter(Boolean)),

			vcSipAddress: data.videoSource === WebcastVideoSource.SIP_ADDRESS || data.videoSource === WebcastVideoSource.PEXIP ?
				data.vcSipAddress : undefined,

			liveSubtitles: {
				isLiveSubtitlesEnabled: !!data.liveSubtitles,
				...data.liveSubtitles
			},

			webexTeam: data.videoSource === WebcastVideoSource.WEBEX && data.webexTeam ?
				Object.assign({ sipAddress: data.vcSipAddress }, data.webexTeam) :
				undefined,

			vcMicrosoftTeamsMeetingUrl: data.videoSource === WebcastVideoSource.MSTEAMS ? data.vcMicrosoftTeamsMeetingUrl : undefined,

			polls: data.polls,

			categories: (data.categoryIds || []).map(categoryId => ({ categoryId })),

			webcastBrandingSettings: data.webcastBrandingSettings,

			preProduction: data.preProduction && {
				...data.preProduction,
				attendees: [
					...mapAccessEntity(AccessEntityType.User, data.preProduction.userIds),
					...mapAccessEntity(AccessEntityType.Group, data.preProduction.groupIds)
				]
			},

			registrationFields: data.registrationFields || [],
			hideShareUrl: !!data.hideShareUrl,
			webcastType: data.webcastType,
			vodInfo: data.videoSource === WebcastVideoSource.VOD && data.vodInfo ? {
				...data.vodInfo,
				id: data.vodId,
				durationMs: parseTimespan(data.vodInfo.duration)
			} : undefined,
			producerBgImages: data.producerBgImages || [],
			customConsentDetails: data.customConsentDetails || {
				customConsentVerbiageUpdates: [],
				isCustomConsentEnabled: false,
				consentVerbiage: ''
			},
			isCustomConsentEnabled: !!data.customConsentDetails?.isCustomConsentEnabled,
			consentVerbiage: data.customConsentDetails?.consentVerbiage
		});
	}
}

function mapAccessEntity(type: AccessEntityType, ids: string[]): IAccessEntityIdentifier[] {
	return (ids || []).map(id => toAccessEntity(type, id));
}

function toAccessEntity(type: AccessEntityType, id: string): IAccessEntityIdentifier {
	return { id, type };
}

export interface IEditPresenter {
	data?: IExternalPresenter;
	edits?: IExternalPresenter;
	isEdit: boolean;
	isNew?: boolean;
	sendingEmail?: boolean;
}

export function readExternalPresenter(p: IExternalPresenter): IEditPresenter {
	return {
		data: { ...p },
		isEdit: false
	};
}

export interface ICustomConsentVerbiageUpdate {
	submissionTimeStamp: Date | string;
	name: string;
	consentVerbiage: string;
	username: string;
}
