
<ng-template #accessEntityOptionRow let-item="item" let-insight="insight">
	<access-entity-option-row [item]="item" [insight]="insight"></access-entity-option-row>
</ng-template>

<ng-template #accessEntityAssignedRow let-item="item" let-insight="insight">
	<access-entity-assigned-row [item]="item" [insight]="insight"></access-entity-assigned-row>
</ng-template>

<video-search-sidebar-header [title]="'VideoSettings' | translate" [titleId]="'bulkEditForm'">
</video-search-sidebar-header>

<form class="sidebar-form vb-scrollbar" #form="ngForm" (submit)="submit()" aria-labelledby="bulkEditForm">
	<fieldset class="theme-primary-font-border-fade-50">
		<vb-ui-form-group>
			<label for="isActive" #uiLabel>
				{{ 'Status' | translate }}:
			</label>
			<select name="isActive" class="form-select" [(ngModel)]="fields.isActive.value" (ngModelChange)="onStatusChange()" uiControl>
				<option [ngValue]="null"></option>
				<option [ngValue]="true">{{ 'Active' | translate }}</option>
				<option [ngValue]="false">{{ 'Inactive' | translate }}</option>
			</select>
		</vb-ui-form-group>
	</fieldset>

	@if (fields.isActive.value) {
		<fieldset class="theme-primary-font-border-fade-50">
			<vb-ui-form-group>
				<label for="expirationOp" #uiLabel>
					{{ 'Expiration' | translate }}:
				</label>
				<select name="expirationOp" class="form-select" [(ngModel)]="fields.expiration.operation" uiControl>
					<option [ngValue]="null"></option>
					@for (operator of replaceRemoveOperators; track operator) {
						<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
					}
				</select>
			</vb-ui-form-group>

			@if (fields.expiration.operation === BulkEditOperator.replace) {
				<vb-video-expiration-options [expirationRuleEnabled]="mediaFeatures.enableExpirationRules" [expirationRules]="expirationRules" [expirationDetails]="expirationConfig" (onExpirationChange)="onExpirationChange($event)" [isLarge]="true">
				</vb-video-expiration-options>
			}
		</fieldset>
	}

	<fieldset class="theme-primary-font-border-fade-50">
		<vb-ui-form-group>
			<label for="publishOp" #uiLabel>
				{{ 'Publish' | translate }}:
			</label>
			<select name="publishOp" class="form-select" [(ngModel)]="fields.publishDate.operation" uiControl>
				<option [ngValue]="null"></option>
				@for (operator of publishDateOperators; track operator) {
					<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
				}
			</select>
		</vb-ui-form-group>

		@if (fields.publishDate.operation === BulkEditOperator.replace) {
			<vb-ui-form-group [controlClass]="'vb-ui-form-group-control'">
				<div uiControl>
					<vb-ui-date-picker required name="publishDate" #publishDate="ngModel" [(ngModel)]="fields.publishDate.value" [vbMax]="maxPublishDate" [required]="fields.publishDate.operation === BulkEditOperator.replace">
					</vb-ui-date-picker>
					@if (!publishDate?.invalid) {
						@if (showFutureActiveText) { <div class="help-block theme-primary-txt">{{ 'PublishHelpText' | translate : { '0': midnight } }}</div> }
						@if (showImmediateActiveText) { <div class="help-block theme-primary-txt">{{ 'PublishHelpTextForImmediateActive' | translate }}</div> }
					}
				</div>
				@if (publishDate?.invalid) {
					<div role="alert" uiValidation vbUiErrorMessage>
						@if (publishDate.errors.vbMax) { <div>{{ 'PublishDateWarning' | translate }}</div> }
						@if (publishDate.errors.date) { <div>{{ 'ValidDateRequired' | translate }}</div> }
						@if (publishDate.errors.required) { <div>{{ 'ThisFieldIsRequired' | translate }}</div> }
					</div>
				}
			</vb-ui-form-group>
		}
	</fieldset>

	<fieldset class="theme-primary-font-border-fade-50">
		<vb-ui-form-group>
			<label for="owner" #uiLabel>
				{{ 'Media_Videos_Settings_Owner' | translate }}:
			</label>
			<vb-ui-single-user-select uiControl name="owner" [(ngModel)]="fields.owner.value" [searchQuery]="searchQuery" [ariaLabel]="'Media_Videos_Settings_Owner' | translate">
			</vb-ui-single-user-select>
		</vb-ui-form-group>
	</fieldset>

	<fieldset class="theme-primary-font-border-fade-50">
		<vb-ui-form-group>
			<label for="accessControl" #uiLabel>
				{{ 'Media_Videos_Settings_AccessControl' | translate }}:
			</label>
			<select name="accessControl" class="form-select" [(ngModel)]="fields.accessControl.value" uiControl>
				<option [ngValue]="null"></option>
				@for (accessControl of accessControlSettings; track accessControl) {
					<option [ngValue]="accessControl">{{ accessControlLabels[accessControl] | translate }}</option>
				}
			</select>
		</vb-ui-form-group>

		<vb-ui-form-group class="acl-op">
			<label for="accessEntitiesOp" #uiLabel>
				{{ 'Media_Videos_Settings_AccessControlList' | translate }}:
			</label>
			<select name="accessEntitiesOp" class="form-select" [(ngModel)]="fields.accessControlEntities.operation" (ngModelChange)="onAccessControlEntitiesOperationChange()" uiControl>
				<option [ngValue]="null"></option>
				@for (operator of appendReplaceRemoveOperators; track operator) {
					<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
				}
			</select>
		</vb-ui-form-group>

		@if (showAccessControlEntities) {
			<vb-ui-form-group class="acl" [controlClass]="'vb-ui-form-group-control'">
				<insight uiControl name="accessEntities" #accessEntities="ngModel" [(ngModel)]="fields.accessControlEntities.value" required [insightOptions]="accessControlInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow">
				</insight>
				@if (accessEntities?.invalid) {
					<div role="alert" vbUiErrorMessage uiValidation>
						@if (accessEntities.errors.required) { <div>{{ 'ThisFieldIsRequired' | translate }}</div> }
					</div>
				}
			</vb-ui-form-group>
		}
	</fieldset>

	@if (mediaFeatures.enableCategories) {
		<fieldset class="theme-primary-font-border-fade-50">
			<vb-ui-form-group>
				<label for="categoriesOp" #uiLabel>
					{{ 'Media_VideoCategories' | translate }}:
				</label>
				<select name="categoriesOp" class="form-select" [(ngModel)]="fields.categories.operation" uiControl>
					<option [ngValue]="null"></option>
					@for (operator of appendReplaceRemoveOperators; track operator) {
						<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
					}
				</select>
			</vb-ui-form-group>

			@if (fields.categories.operation) {
				<vb-ui-form-group [controlClass]="'vb-ui-form-group-control'">
					<vb-categories-tags-input uiControl required name="categoryPicker" #categoryPicker="ngModel" [(ngModel)]="fields.categories.value" [whitelist]="categories">
					</vb-categories-tags-input>
					@if (categoryPicker?.invalid) {
						<div role="alert" uiValidation vbUiErrorMessage>
							@if (categoryPicker.errors.required) { <div>{{ 'ThisFieldIsRequired' | translate }}</div> }
						</div>
					}
				</vb-ui-form-group>
			}
		</fieldset>
	}

	@if (mediaFeatures.enableTags) {
		<fieldset class="theme-primary-font-border-fade-50">
			<vb-ui-form-group>
				<label for="tagsOp" #uiLabel>
					{{ 'Tags' | translate }}:
				</label>
				<select name="tagsOp" class="form-select" [(ngModel)]="fields.tags.operation" uiControl>
					<option [ngValue]="null"></option>
					@for (operator of appendReplaceRemoveOperators; track operator) {
						<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
					}
				</select>
			</vb-ui-form-group>

			@if (fields.tags.operation) {
				<vb-ui-form-group [controlClass]="'vb-ui-form-group-control'">
					<vb-tags-input [ngClass]="{ 'tags-input-has-items': fields.tags.value.length }" [placeholder]="'TagsInputPlaceholder' | translate " [(ngModel)]="fields.tags.value" name="tags" #tags="ngModel" required uiControl>
					</vb-tags-input>
					@if (tags?.invalid) {
						<div role="alert" vbUiErrorMessage uiValidation>
							@if (tags.errors.required) { <div>{{ 'ThisFieldIsRequired' | translate }}</div> }
						</div>
					}
				</vb-ui-form-group>
			}
		</fieldset>
	}

	<fieldset class="theme-primary-font-border-fade-50">
		<vb-ui-form-group>
			<label for="userTagsOp" #uiLabel>
				{{ 'InThisVideo' | translate }}:
			</label>
			<select name="userTagsOp" class="form-select" [(ngModel)]="fields.userTags.operation" uiControl>
				<option [ngValue]="null"></option>
				@for (operator of appendReplaceRemoveOperators; track operator) {
					<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
				}
			</select>
		</vb-ui-form-group>

		@if (fields.userTags.operation) {
			<vb-ui-form-group class="acl" [controlClass]="'vb-ui-form-group-control'">
				<insight name="userTags" #userTags="ngModel" [(ngModel)]="fields.userTags.value" required uiControl [insightOptions]="userTagsInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow">
				</insight>
				@if (userTags?.invalid) {
					<div role="alert" uiValidation vbUiErrorMessage>
						@if (userTags.errors.required) { <div>{{ 'ThisFieldIsRequired' | translate }}</div> }
					</div>
				}
			</vb-ui-form-group>
		}
	</fieldset>

	<fieldset class="theme-primary-font-border-fade-50">
		<vb-ui-form-group>
			<label for="showChapterImagesByDefaultEnabled" #uiLabel>
				{{ 'Media_Videos_Settings_EnableAutoShowChapterImages' | translate }}:
			</label>
			<select name="showChapterImagesByDefaultEnabled" class="form-select" [(ngModel)]="fields.enableAutoShowChapterImages.value" uiControl>
				<option [ngValue]="null"></option>
				<option [ngValue]="true">{{ 'UI_Enabled' | translate }}</option>
				<option [ngValue]="false">{{ 'UI_Disabled' | translate }}</option>
			</select>
		</vb-ui-form-group>
	</fieldset>

	@if (mediaFeatures.enableDownloads || mediaFeatures.enableComments || mediaFeatures.enableRatings) {
		<fieldset class="theme-primary-font-border-fade-50">
			@if (mediaFeatures.enableDownloads) {
				<vb-ui-form-group>
					<label for="downloadingEnabled" #uiLabel>
						{{ 'Event_Downloads' | translate }}:
					</label>
					<select name="downloadingEnabled" class="form-select" [(ngModel)]="fields.downloadingEnabled.value" uiControl>
						<option [ngValue]="null"></option>
						<option [ngValue]="true">{{ 'UI_Enabled' | translate }}</option>
						<option [ngValue]="false">{{ 'UI_Disabled' | translate }}</option>
					</select>
				</vb-ui-form-group>
			}
			@if (mediaFeatures.enableComments) {
				<vb-ui-form-group>
					<label for="commentsEnabled" #uiLabel>
						{{ 'Media_VideoComments' | translate }}:
					</label>
					<select name="commentsEnabled" class="form-select" [(ngModel)]="fields.commentsEnabled.value" uiControl>
						<option [ngValue]="null"></option>
						<option [ngValue]="true">{{ 'UI_Enabled' | translate }}</option>
						<option [ngValue]="false">{{ 'UI_Disabled' | translate }}</option>
					</select>
				</vb-ui-form-group>
			}
			@if (mediaFeatures.enableRatings) {
				<vb-ui-form-group>
					<label for="ratingsEnabled" #uiLabel>
						{{ 'Ratings' | translate }}:
					</label>
					<select name="ratingsEnabled" class="form-select" [(ngModel)]="fields.ratingsEnabled.value" uiControl>
						<option [ngValue]="null"></option>
						<option [ngValue]="true">{{ 'UI_Enabled' | translate }}</option>
						<option [ngValue]="false">{{ 'UI_Disabled' | translate }}</option>
					</select>
				</vb-ui-form-group>
			}
		</fieldset>
	}

	@if (mediaFeatures.enableUnlisted) {
		<fieldset class="theme-primary-font-border-fade-50">
			<vb-ui-form-group>
				<label for="unlisted" #uiLabel>
					{{ 'Unlisted' | translate }}:
				</label>
				<select name="unlisted" class="form-select" [(ngModel)]="fields.unlisted.value" uiControl>
					<option [ngValue]="null"></option>
					<option [ngValue]="true">{{ 'UI_Enabled' | translate }}</option>
					<option [ngValue]="false">{{ 'UI_Disabled' | translate }}</option>
				</select>
			</vb-ui-form-group>
		</fieldset>
	}

	@for (field of fields.customFieldValues; track field; let i = $index) {
		<fieldset class="theme-primary-font-border-fade-50">
			<vb-ui-form-group>
				<label for="customFieldCtrl" #uiLabel>
					{{field.name}}:
				</label>
				<select [name]="'customFieldCtrl' + i" class="form-select" [(ngModel)]="field.operation" uiControl>
					<option [ngValue]="null"></option>
					@for (operator of getCustomFieldOperators(field.required); track operator) {
						<option [ngValue]="operator">{{ operationLabels[operator] | translate }}</option>
					}
				</select>
			</vb-ui-form-group>

			@if (field.operation === BulkEditOperator.replace) {
				@switch (field.fieldType) {
					@case (FieldType.Text) {
						<vb-ui-form-group [controlClass]="'vb-ui-form-group-control'">
							<input type="text" [name]="'customField' + i" [attr.aria-label]="field.name" [(ngModel)]="field.value" required vbUiTextInput uiControl/>
						</vb-ui-form-group>
						@if (form.controls?.['customField' + i]?.invalid) {
							<div role="alert" uiValidation vbUiErrorMessage>{{ 'ThisFieldIsRequired' | translate }}</div>
						}
					}
					@case (FieldType.Select) {
						<vb-ui-form-group [controlClass]="'vb-ui-form-group-control'">
							<select [name]="'customField' + i" class="form-select" [(ngModel)]="field.value" uiControl>
								<option [ngValue]="null"></option>
								@for (option of field?.options; track option) {
									<option [ngValue]="option">{{ option }}</option>
								}
							</select>
						</vb-ui-form-group>
						@if (form.controls?.['customField' + i]?.invalid) {
							<div role="alert" uiValidation vbUiErrorMessage>{{ 'ThisFieldIsRequired' | translate }}</div>
						}
					}
				}
			}
		</fieldset>
	}

	<vb-toolbar edge-padding class="section-header">
		<div flex="fill"></div>
		<div flex>
			<button type="button" class="margin-right-5" vbBtnSecondary (click)="cancel()">{{ 'Cancel' | translate }}</button>
			<button type="submit" vbBtnPrimary [disabled]="form?.invalid || !hasAnyOperations || !VideoSelectionModel.selectionCountValid">
				{{ 'Update' | translate }}
			</button>
		</div>
	</vb-toolbar>
</form>
