import { Component, Input } from '@angular/core';

import { noop } from 'rxjs';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { VideoResultsSidebarButton } from 'rev-portal/media/search/VideoSearchResultsTypes';

import { ConfirmationDialogAngularComponent } from 'rev-shared/ui/dialog/ConfirmationDialogAngular.Component';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { VbTranslateService } from 'rev-shared/util/VbTranslate.Service';
import { firstValueFrom } from 'rev-shared/rxjs/firstValueFrom';

import { BulkEditService } from './BulkEdit.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';
@Component({
	selector: 'vb-delete-videos-button',
	template: `
		<button
			type="button"
			role="tab"
			tabindex="-1"
			class="legacySidebarBtn"
			[class.disabled]="disabled"
			[attr.id]="VideoResultsSidebarButton.BULK_EDIT_DELETE_VIDEOS"
			[attr.aria-label]="'Media_BulkEdit_DeleteVideos' | translate"
			[tooltip]="'Media_BulkEdit_DeleteVideos' | translate"
			[adaptivePosition]="false"
			[container]="'body'"
			[placement]="'left'"
			(click)="disabled || submit()"
		>
			<span class="vb-icon vb-icon-center vb-icon-trash"></span>
		</button>
	`
})
export class VbDeleteVideosButtonComponent {
	@Input() public loadSearchResults: () => Promise<void>;

	public readonly VideoResultsSidebarButton = VideoResultsSidebarButton;
	private processingDialogInstance: ConfirmationDialogAngularComponent;
	private processingDialogTimer: number;

	constructor(
		private BulkEditService: BulkEditService,
		private DialogService: DialogService,
		private MediaStateService: MediaStateService,
		private VideoSelectionModel: VideoSelectionModelService,
		private VbTranslate: VbTranslateService
	) { }

	public get disabled(): boolean {
		return this.VideoSelectionModel.selectionCount < 1 ||
			this.VideoSelectionModel.selectionCount > this.VideoSelectionModel.limit;
	}

	private closeProcessingDialog(): void {
		window.clearTimeout(this.processingDialogTimer);

		if (this.processingDialogInstance) {
			this.processingDialogInstance.hide();
		}

		this.processingDialogTimer = this.processingDialogInstance = null;
	}

	private deleteVideos(): void {
		this.openProcessingDialog();

		this.ensureSelectionLoaded()
			.then(() => this.BulkEditService.deleteVideos(this.VideoSelectionModel.selectedVideos.map(v => v.id)))
			.then(({ message }) => {
				this.closeProcessingDialog();
				return firstValueFrom(
					this.DialogService.getDialog('bulkEditSubmission')
						.open({
							initialState: {
								legalHoldVideosCount: message.legalHoldVideosCount,
								recordingVideosCount: message.recordingVideosCount,
								isDeleting: true
							}
						}).onHide
				).then(() => this.MediaStateService.navigateBack());
			})
			.catch(e => console.error('Error Deleting Videos: ', e))
			.catch(e => {
				console.error('Delete videos error: ', e);

				this.DialogService.openAlertDialog(...this.VbTranslate.translateKeys([
					'Error',
					'Media_BulkEdit_ProcessingError',
					'Ok']));
			})
			.finally(() => this.closeProcessingDialog());
	}

	private ensureSelectionLoaded(): Promise<void> {
		if (this.VideoSelectionModel.isSelectAll) {
			return this.loadSearchResults();
		}

		return Promise.resolve();
	}

	private openProcessingDialog(): void {
		this.processingDialogTimer = window.setTimeout(() => {
			this.processingDialogInstance = this.DialogService.openConfirmationDialog(
				this.VbTranslate.translateObj({
					title: 'VideoSettings',
					message: 'Media_BulkEdit_Processing'
				}));
		}, 1000);
	}

	public submit(): void {
		this.DialogService.openConfirmationDialog(
			this.VbTranslate.translateObjWParams({
				title: ['Media_BulkEdit_DeleteVideos'],
				message: ['Media_BulkEdit_DeleteConfirmation', { '0': this.VideoSelectionModel.selectionCount }],
				cancelText: ['Cancel'],
				actionText: ['Ok']
			})
		).result
			.then(() => this.deleteVideos())
			.catch(noop);
	}
}
