<div name="videoExpirationForm" [ngClass]="{
		'video-expiration-fixed-height': expirationOption !== expirationOptions.NONE
	}" ngModelGroup #videoExpForm="ngModelGroup">
	<!-- Buttons -->
	<vb-ui-form-group>
		<label [ngClass]="themedLabel ? 'theme-primary-txt': ''" for="selectEvent" #uiLabel [hidden]="config.hideLabel" id="videoExpirationOptions">
			{{ 'Expiration' | translate }}
		</label>
		<div vbUiRadioBtnGroup uiControl>
			<button name="expOptNone" type="button" [vbUiRadioBtn]="expirationOptions.NONE" [(ngModel)]="expirationOption" (ngModelChange)="onExpirationUpdate()" [hidden]="hideNoneOption">
				{{ 'None' | translate }}
			</button>
			<button name="expOptDate" type="button" [vbUiRadioBtn]="expirationOptions.DATE" [(ngModel)]="expirationOption" [ngModelOptions]="{ debounce: 150 }" (ngModelChange)="onExpirationUpdate()" [hidden]="config.hideExpirationOption === expirationOptions.DATE">
				{{ 'Date' | translate }}
			</button>
			<button name="expOptRule" type="button" [vbUiRadioBtn]="expirationOptions.RULE" [(ngModel)]="expirationOption" [ngModelOptions]="{ debounce: 150 }" *ngIf="expirationRuleEnabled" (ngModelChange)="onExpirationUpdate()">
				{{ 'Rule' | translate }}
			</button>
		</div>
	</vb-ui-form-group>
<!-- Date -->
	<vb-ui-form-group class="margin-top-5" *ngIf="expirationOption === expirationOptions.DATE">
		<label for="expDate" #uiLabel>{{ 'Date' | translate }}</label>
		<div uiControl>
			<vb-ui-date-picker name="expDate" #expDate="ngModel" [(ngModel)]="expirationDetails.expirationDate" (ngModelChange)="onExpirationUpdate($event)" [vbMin]="tomorrow" required [accessibilityLabel]="'Date' | translate">
			</vb-ui-date-picker>

			<span *ngIf="!expirationDetails?.expirationDate; else deleteCheckbox" class="help-block theme-primary-txt">
					{{ 'ExpirationHelpText' | translate : { '0': midnight } }}
			</span>
			<ng-template #deleteCheckbox>
				<vb-ui-checkbox name="deleteOnExpiration" class="padding-top-10" [(ngModel)]="expirationDetails.deleteOnExpiration" (ngModelChange)="onExpirationUpdate()" [accessibilityLabel]="'DeleteOnExpiration' | translate">
						{{ 'DeleteOnExpiration' | translate }}
				</vb-ui-checkbox>
			</ng-template>
		</div>

		<div role="alert" uiValidation vbUiErrorMessage *ngIf="videoExpForm?.control?.controls.expDate?.invalid">
			<div *ngIf="videoExpForm.control.controls.expDate.errors.required">
				{{ 'DatePicker_Validation_Format' | translate : { '0': tomorrow | date: 'mediumDate' } }}
			</div>
			<div *ngIf="videoExpForm.control.controls.expDate.errors.date">
				{{ 'DatePicker_Validation_Format' | translate : { '0': tomorrow | date: 'mediumDate' } }}
			</div>
			<div *ngIf="videoExpForm.control.controls.expDate.errors.vbMin">
				{{ 'ExpirationDateWarning' | translate }}
			</div>
		</div>
	</vb-ui-form-group>
<!-- Rules -->
	<vb-ui-form-group class="expiration-rule margin-top-5" *ngIf="expirationRuleEnabled && expirationOption === expirationOptions.RULE">
		<label for="expRule" #uiLabel *ngIf="!config.hideExpirationOptionLabel">{{ 'Rule' | translate }}</label>
		<vb-ng-select-wrap uiControl>
			<ng-select [items]="expirationRules.rules" [(ngModel)]="expirationRule" bindLabel="name" [placeholder]="'ExpiryRule_SelectRule' | translate" name="expRule" labelForId="expRule" required #expRule="ngModel" (change)="onExpiryRuleChange($event)">
				<ng-template ng-option-tmp let-item="item">
					<div>{{ item.name }}</div>
					<small [hidden]="!(item.id && item.ruleType === ruleTypes.DAYS_AFTER_UPLOAD)">
						{{ (expirationDetails?.expirationDate ? 'ExpiryRuleTypeLabel_DaysFromToday' : 'ExpiryRuleTypeLabel_DaysAfterApplying') | translate : { '0': item.numberOfDays } }}
					</small>
					<small [hidden]="!(item.id && item.ruleType === ruleTypes.DAYS_WITHOUT_VIEW)">
						{{ 'ExpiryRuleTypeLabel_DaysWithoutViews' | translate : { '0': item.numberOfDays } }}
					</small>
				</ng-template>
			</ng-select>

			<span *ngIf="expirationRule?.expiryRuleDate" [ngClass]="config.notThemed ? '' : 'theme-primary-txt'" class="help-block">
				{{ (expirationRule?.deleteOnExpire ? 'Media_Videos_Settings_Expiration_SelectedRule_Delete_HelpText' : 'Media_Videos_Settings_Expiration_SelectedRule_Inactive_HelpText') | translate : { '0': midnight, '1': expirationRule.expiryRuleDate | date:'mediumDate' } }}
			</span>
		</vb-ng-select-wrap>

		<div role="alert" class="margin-bottom-10" vbUiErrorMessage uiValidation *ngIf="expRule?.invalid">
			<div *ngIf="expRule.errors.required">
				{{ 'ThisFieldIsRequired' | translate }}
			</div>
		</div>
	</vb-ui-form-group>
</div>
