import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';

import { HelpModule } from 'rev-portal/navigation/help/Help.Module';
import { MediaMenuAngularModule } from 'rev-portal/navigation/mediaMenu/MediaMenuAngular.Module';
import { SearchHeaderAngularModule } from 'rev-portal/navigation/searchHeader/SearchHeaderAngular.Module';

import { MobileMenuComponent } from './MobileMenu.Component';
import { VbDropdownMenuModule } from 'rev-shared/ui/dropdown-menu/VbDropdownMenu.Module';

const declarations = [
	MobileMenuComponent
];

@NgModule({
	declarations,
	imports: [
		BsDropdownModule,
		CommonModule,
		CssRulesAngularModule,
		HelpModule,
		MediaMenuAngularModule,
		SecurityAngularModule,
		TranslateModule,
		UIRouterModule,
		SearchHeaderAngularModule,
		VbDropdownMenuModule
	],
	exports: declarations
})

export class MobileMenuModule {}
