import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CaretAngularModule } from 'rev-shared/ui/caret/CaretAngular.Module';
import { VbDropdownMenuModule } from 'rev-shared/ui/dropdown-menu/VbDropdownMenu.Module';

import { HelpMenuComponent, HelpMenuMobileComponent } from './HelpMenu.Component';

const components = [
	HelpMenuComponent,
	HelpMenuMobileComponent
];
@NgModule({
	declarations: [
		components
	],
	imports: [
		ButtonsAngularModule,
		CommonModule,
		TranslateModule,
		BsDropdownModule,
		CaretAngularModule,
		VbDropdownMenuModule
	],
	exports: [
		components
	]
})
export class HelpModule { }
