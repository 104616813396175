import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { MainHeaderAngularModule } from 'rev-portal/navigation/mainHeader/MainHeader.AngularModule';

import { AnchorLinkModule } from 'rev-shared/ui/anchorLink/AnchorLink.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FlickityCarouselModule } from 'rev-shared/ui/flickityCarousel/FlickityCarousel.AngularModule';
import { VbrickSharedMediaModule } from 'rev-shared/media/Media.Module';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';
import { VideoPlayerAngularModule } from 'rev-shared/videoPlayer/VideoPlayerAngular.Module';

import { FeaturedVideoPlayerCarouselComponent } from './FeaturedVideoPlayerCarousel.Component';
import { FeaturedVideoThumbnailCarouselComponent } from './FeaturedVideoThumbnailCarousel.Component';
import { FeaturedVideoThumbnailComponent } from './FeaturedVideoThumbnail.Component';
import { FeaturedVideoThumbnailGridComponent } from './FeaturedVideoThumbnailGrid.Component';
import { FeaturedVideosComponent } from './FeaturedVideos.Component';

@NgModule({
	declarations: [
		FeaturedVideoThumbnailCarouselComponent,
		FeaturedVideoPlayerCarouselComponent,
		FeaturedVideoThumbnailGridComponent,
		FeaturedVideoThumbnailComponent,
		FeaturedVideosComponent
	],
	exports: [FeaturedVideosComponent],
	imports: [
		AnchorLinkModule,
		ButtonsAngularModule,
		CommonModule,
		CssRulesAngularModule,
		DirectivesAngularModule,
		FlickityCarouselModule,
		MainHeaderAngularModule,
		TranslateModule,
		UIRouterModule.forChild(),
		VbrickSharedMediaModule,
		Video360IndicatorModule,
		VideoPlayerAngularModule
	]
})
export class FeaturedVideosModule {}
