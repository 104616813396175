<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<nav class="container-fluid theme-header theme-accent-border" role="navigation" layout="row" layout-wrap="false" flex-align="center">

	<mobile-menu [hidden]="isRegisteredGuest" flex-gt-md="hide" [theme]="currentTheme" [headerSettings]="headerSettings" [themeSettings]="themeSettings" [isAnalystAdmin]="isAnalystAdmin" [showManageSubscriptions]="showManageSubscriptions" [landingState]="landingState">
	</mobile-menu>
	<div display="flex" flex-align="center" flex="1 1 0">
		<!-- Logo -->
		<div class="brand-cell" flex="fill" flex-gt-md>

			<a uiSref="void" class="skip-link" (click)="skipNavigation()">{{ 'SkipToMainContent' | translate }}</a>

			<a [attr.aria-label]="('Application_CompanyLogo' | translate) + ' - ' + ('Home' | translate)" href="#/" class="navbar-brand theme-logo" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
				<branding-logo></branding-logo>
			</a>

			<div *vbAuthorizationKey="['authenticated', 'distribution.authenticated']; fallback true">
				<span *ngIf="guestPortalRestricted; else guestPortalAllowed;" class="navbar-brand">
					<branding-logo></branding-logo>
				</span>
				<ng-template #guestPortalAllowed>
					<a [attr.aria-label]="('Application_CompanyLogo' | translate) + ' - ' + ('Home' | translate)" [href]="'#/guest'" class="navbar-brand" [tabindex]="0">
						<branding-logo></branding-logo>
					</a>
				</ng-template>
			</div>
		</div>

		<!-- Media Menu -->
		<media-menu-dropdown flex="hide" flex-gt-md class="theme-header-txt" *vbAuthorizationKey="'authenticated'">
		</media-menu-dropdown>

		<!-- Events -->
		<div class="main-nav-links theme-header-txt" flex="hide" flex-gt-md>
			<a class="padding-top-2 text-color-inherit" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']" uiSref="portal.scheduledEvents.all" [uiOptions]="{reload: true, inherit: false}" uiSrefActive="active">
				{{ 'Events' | translate }}
			</a>
		</div>

		<span style="flex-grow: 1"></span>
		<!-- Search -->
		<ng-container *vbAuthorizationKey="'distribution.authenticated'; fallback true">
			<search-header class="utility-section search-cell theme-Classic-search-header" flex="hide" flex-gt-md="fill" role="search" *ngIf="!guestPortalRestricted" [showSearchText]="false" [headerType]="'Classic'">
			</search-header>
		</ng-container>
		<span style="flex-grow: 1"></span>
	</div>
	<div display="flex" flex-align="center" class="flex-center-align right-side-container">
		<go-live class="box-block utility-section" [iconCssClass]="''" [alwaysThemeAccent]="true" [dropdownCssClass]="'align-right'" [headerType]="'Classic'">
		</go-live>
		<!-- Upload -->
		<div class="dropdown utility-section upload-cell" dropdown vbDropdown #headerUploadMenuDropdown="bs-dropdown" [insideClick]="true" *vbAuthorizationKey="'media.add'">
			<button aria-controls="upload-dropdown" [attr.aria-label]="('Upload' | translate) + ' - ' + ('UI_Menu' | translate)" dropdownToggle vbBtnSecondary class="dropdown-toggle flex-center-align upload main-nav-btn line-height-1">
				<span class="vb-icon vb-icon-upload">
				</span>
			</button>
			<div *dropdownMenu id="upload-dropdown" role="menu" class="dropdown-menu dropdown-menu-right upload-media">
				<upload-menu (onCloseUploadMenu)="headerUploadMenuDropdown.hide()">
				</upload-menu>
			</div>
		</div>

		<!-- User Menu (authenticated) -->
		<div dropdown vbDropdown #headerUserMenuDropdown="bs-dropdown" class="dropdown utility-section user-profile" flex="hide" flex-gt-md *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
			<button dropdownToggle class="dropdown-toggle main-nav-btn btn" [attr.aria-label]="(user.username) + ' - ' + ('UI_Menu' | translate)" vbBtnSecondary aria-controls="user-dropdown" type="button">
				<vb-profile-picture [altTxt]="'UserProfile_ProfilePicture' | translate" [profileUrl]="user.profileImageUri">
				</vb-profile-picture>
				<span>{{ user.fullName | slice:0:30 }}</span>
			</button>

			<div *dropdownMenu id="user-dropdown" role="menu" class="dropdown-menu user-profile-menu">
				<user-nav-menu name="userNavMenu" [user]="user" [showManageSubscriptions]="showManageSubscriptions" [readonlyUserProfile]="account.readonlyUserProfile" [isBaseHeaderBgColor]="true">
				</user-nav-menu>
			</div>
		</div>

		<!-- Login (unauthenticated) -->
		<button type="button" class="utility-section user-cell btn main-nav-btn theme-accent-btn text-color-inherit" flex="hide" flex-gt-md (click)="loginRedirect()" [hidden]="isRegisteredGuest" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']; fallback true">
			<span class="glyphicons user"></span>
			<span>{{ 'SignIn' | translate }}</span>
		</button>

		<!-- Notifications -->
		<notifications-menu-button *ngIf="isUserAuthenticated" class="notification-cell utility-section" [glyphiconCssClass]="'theme-accent-txt'" [isBaseHeaderBgColor]="true">
		</notifications-menu-button>

		<!-- Admin -->
		<a [attr.aria-label]="(isAnalystAdmin ? 'Analytics' : 'Admin') | translate " [attr.aria-pressed]="$state.includes('portal.admin') " class="flex-center-align admin-btn utility-section text-color-inherit main-nav-btn line-height-1" flex="hide" flex-gt-md uiSref="portal.load-admin" [uiParams]="{accountId: accountId}" *vbAuthorizeState="'portal.admin'">
			<span [ngClass]="{ 'glyphicons charts': isAnalystAdmin, 'vb-icon vb-icon-settings': !isAnalystAdmin }"></span>
		</a>

		<!-- Help -->
		<help-menu class="utility-section margin-top-0" flex="hide" flex-gt-md [buttonMode]="true" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
		</help-menu>
	</div>
</nav>
